import { type Ref, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions, ElementTypes } from '@shared/types/options';
import { purify } from '@shared/utils';

export function useLabelOptions({ element, pageOptions }: {element: Ref<ElementType<ElementTypes>>; pageOptions: Ref<PageOptions>}) {

  function getInputLabel(label = '', show = true) {
    return purify(show ? label || '' : '');
  }

  const values = computed(() => JSON.parse(element.value.values || '{}')) ;
  const isLabelVisible = computed(() => element.value.options?.label?.show);
  const labelText = computed(() => getInputLabel(values?.value.label, isLabelVisible.value))

  const labelStyle = computed(() => {
    const color = element.value.options.label?.color || pageOptions.value.colors?.text[0];
    const totalLineColor = (element.value.options as ElementOptions['payment']).label?.totalLineColor || pageOptions.value.colors?.text[0];
    const fontFamily = element.value.options.label?.fontFamily || pageOptions.value.fontFamily;
    const fontSize = `${element.value.options.label?.fontSize || 14}px`;
    const lineHeight = `${Math.round((element.value.options.label?.fontSize || 14) * 1.3)}px`;
    const textStyle = {
      bold: element.value.options.label?.textStyle?.bold ? 'bold' : '',
      italic: element.value.options.label?.textStyle?.italic ? 'italic' : '',
      underline: element.value.options.label?.textStyle?.underline ? 'underline' : '',
      strikeThrough: element.value.options.label?.textStyle?.strikeThrough ? 'line-through' : ''
    }
    const fontWeight = textStyle.bold || 500;
    const fontStyle = textStyle.italic || 'inherit';
    const textDecoration = `${textStyle.underline} ${textStyle.strikeThrough}`.trim();

    return {
      color,
      totalLineColor,
      fontFamily,
      fontSize,
      fontWeight,
      lineHeight,
      textDecoration,
      fontStyle
    };
  })
  const consentStyle = computed(() => {
    const color = (element.value.options as ElementOptions['payment']).consent?.color || pageOptions.value.colors?.text[0];
    const fontFamily = (element.value.options as ElementOptions['payment']).consent?.fontFamily || pageOptions.value.fontFamily;
    const fontSize = `${(element.value.options as ElementOptions['payment']).consent?.fontSize || 14}px`;
    const lineHeight = `${Math.round(((element.value.options as ElementOptions['payment']).consent?.fontSize || 14) * 1.3)}px`;
    const textStyle = {
      bold: (element.value.options as ElementOptions['payment']).consent?.textStyle?.bold ? 'bold' : '',
      italic: (element.value.options as ElementOptions['payment']).consent?.textStyle?.italic ? 'italic' : '',
      underline: (element.value.options as ElementOptions['payment']).consent?.textStyle?.underline ? 'underline' : '',
      strikeThrough: (element.value.options as ElementOptions['payment']).consent?.textStyle?.strikeThrough ? 'line-through' : ''
    }
    const fontWeight = textStyle.bold || 500;
    const fontStyle = textStyle.italic || 'inherit';
    const textDecoration = `${textStyle.underline} ${textStyle.strikeThrough}`.trim();

    return {
      color,
      fontFamily,
      fontSize,
      fontWeight,
      lineHeight,
      textDecoration,
      fontStyle
    };
  })

  return {
    values,
    labelStyle,
    consentStyle,
    labelText,
    isLabelVisible
  };
}
